<script lang="ts" setup>
import { groups } from '~/constants/LeaseStatus'
import type { LeaseStatus } from '@register'
import type {
  ISearchPropertyLeasePortfolioRequest,
  IPropertyLeasePortfolioSummaryResponse,
} from '@portfolio'

const props = defineProps<{
  companyId: string
  filters: ISearchPropertyLeasePortfolioRequest
}>()

const summaryApi = useApiPortfolioSummary(
  props.companyId,
  toRef(props, 'filters'),
)

interface ISummaryMap {
  key: keyof IPropertyLeasePortfolioSummaryResponse
  title: string
  status: LeaseStatus[]
  selectedStatus: boolean
  tooltip: string
}

const summaryMap = computed<ISummaryMap[]>(() => [
  {
    key: 'totalCount',
    title: 'Total number of leases',
    status: [],
    selectedStatus:
      !props.filters?.statuses || isEqual(props.filters?.statuses, []),
    tooltip: 'All your leases',
  },
  {
    key: 'leasedCount',
    title: 'Current leases',
    status: groups.currents,
    selectedStatus: isEqual(props.filters?.statuses, groups.currents),
    tooltip: `${groups.currents.map((s) => title(s)).join(', ')}`,
  },
  {
    key: 'expiredCount',
    title: 'Leases expired',
    status: groups.expired,
    selectedStatus: isEqual(props.filters?.statuses, groups.expired),
    tooltip: `${groups.expired.map((s) => title(s)).join(', ')}`,
  },
  {
    key: 'othersCount',
    title: 'Others',
    status: groups.others,
    selectedStatus: isEqual(props.filters?.statuses, groups.others),
    tooltip: `${groups.others.map((s) => title(s)).join(', ')}`,
  },
  {
    key: 'holdoverCount',
    title: 'Holdover',
    status: groups.holdOver,
    selectedStatus: isEqual(props.filters?.statuses, groups.holdOver),
    tooltip: `Leases on holdover`,
  },
])
</script>

<template>
  <section class="grid w-full grid-cols-2 gap-6 md:grid-cols-5">
    <NuxtLink
      v-for="s in summaryMap"
      :key="s.key"
      role="button"
      class="border-gray-750 hover:border-primary hover:bg-primary/5 hover:text-primary group cursor-pointer rounded-xl border bg-gray-800 py-6 text-center md:flex-1 md:px-2"
      :class="{
        'border-primary/50 bg-primary/5 text-primary': s.selectedStatus,
      }"
      :to="{
        path: `/division/${companyId}/portfolio`,
        query: { filters: { statuses: s.status } },
      }"
    >
      <p
        class="group-hover:text-primary flex items-center justify-center text-gray-500 md:text-xs lg:text-xs xl:text-base"
      >
        {{ s.title }}
        <span class="ml-2 mt-1 flex self-center">
          <Tooltip
            :content="s.tooltip"
            class="border-gray-750 max-w-[260px] border bg-gray-900"
          >
            <Icon name="info" filled />
          </Tooltip>
        </span>
      </p>
      <p class="group-hover:text-primary text-3xl font-semibold text-white">
        {{ !!summaryApi.data.value ? summaryApi.data.value[s.key] : '-' }}
      </p>
    </NuxtLink>
  </section>
</template>
