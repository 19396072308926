import { useQuery, keepPreviousData } from '@tanstack/vue-query'
import type {
  ISearchPropertyLeasePortfolioRequest,
  IPropertyLeasePortfolioSummaryResponse,
} from '@portfolio'

export const useApiPortfolioSummary = (
  companyId: string,
  filters: Ref<ISearchPropertyLeasePortfolioRequest>,
) => {
  // If the only filter is statuses, we don't filter the summary
  const _filters = computed(() => {
    const keys = Object.keys(filters.value)
    if (keys.length === 1 && keys[0] === 'statuses') {
      return {}
    }
    return filters.value
  })

  return useQuery({
    queryKey: ['getPortfolioSummary', companyId, _filters],
    queryFn: ({ signal }) =>
      fetchAPI<IPropertyLeasePortfolioSummaryResponse>(
        `/companies/${companyId}/portfolio/property/summary`,
        {
          method: 'POST',
          body: _filters.value,
          signal,
        },
      ),
    placeholderData: keepPreviousData,
    gcTime: 60 * 60 * 24 * 7,
    staleTime: 0,
  })
}
